<template>
  <div class="index" id="indexTop">
    <div class="duty-wrap flex flex-direction align-center">
      <div class="duty-title">{{ title }}</div>
      <!-- <div class="duty-title">--传递爱，给爱来一场接力</div> -->
      <div style="width:750px" v-if="showVideo">
        <video id="myVideo1" class="video-js video-play" controls preload="auto" data-setup=""></video>
      </div>
      <div class="duty-content" v-html="content"></div>
      <div class="duty-content-next-pre flex justify-between align-center">
        <div class="pre-text-block">
          <div class="pre-text-block" v-if="artInfo.next_id" @click="preArtical(artInfo.next_id)">上一篇</div>
        </div>
        <div class="pre-text-block">
          <div class="pre-text-block" v-if="artInfo.pre_id" @click="preArtical(artInfo.pre_id)">下一篇</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { getNewsInfo } from '@/api';

export default {
  name: "Duty",

  data(){
    return {
      title: '',
      content: "",
      artInfo: "",
      showVideo: true,
    }
  },

  created(){
    this.id = this.$route.query.id;
    this.$emit("isOther","other");
    this.getInfo();
  },

  metaInfo(){
    return {
      title: this.title + "-品牌动态-春之唤官方网站",
    }
  },


  mounted() {
    // this.initVideo();
  },

  methods: {
    initVideo() {
      //初始化视频方法
      let myPlayer = this.$video(myVideo1, { 
        controls: true, //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。 
        autoplay: true, //自动播放属性,muted:静音播放
        preload: "auto", //建议浏览器是否应在加载元素后立即开始下载视频数据。
      });
    },

    // 资讯列表
    async getInfo(){
      let data = await getNewsInfo({
        news_id: this.id,
      })
      this.title = data.title;
      this.artInfo = data;
      this.content = this.showHtml(data.content)
      
      if(data.url) {
        this.showVideo = true
        setTimeout(() => {
          this.initVideo()
          let myPlayer = this.$video(myVideo1);
          myPlayer.src({
            src: data.url
          })
        },100)
        
      } else {
        this.showVideo = false
      }
    },

    // 富文本特殊符号解析
    showHtml(str){
      return str
        .replace(str ? /&(?!#?\w+;)/g : /&/g, '&amp;')
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, "\"")
        .replace(/&#39;/g, "'")
        .replace(/&amp;nbsp;/g, "\u3000")
    },

    // 上下篇文章
    preArtical(id){
      this.id = id;
      router.replace({
        path: "/companyActiveInfo",
        query: { id: id },
      })
      this.getInfo();
      document.documentElement.scrollTop = 0;
    },
  },
}
</script>

<style scoped>
.index {
  width: 100%;
  height: 100%;
}

.duty-wrap {
  width: 100%;
  margin-top: 70px;
  padding-bottom: 70px;
}

.duty-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 40px;
}

.video-play {
  width: 100%;
  height: 400px;
  margin-top: 30px;
}

.duty-content {
  width: 65%;
  font-size: 16px;
  line-height: 30px;
  color: #9e9d9d;
  text-align: left;
  margin-top: 15px;
}

.duty-content >>> img {
  width: 100% !important;
  display: block;
}

.duty-content-next-pre {
  width: 40%;
  height: 60px;
}

.pre-text-block {
  min-width: 80px;
  font-size: 18px;
  color: #034c3b;
  cursor: pointer;
  margin-top: 15px;
}

.duty-content-img {
  width: 40%;
  margin-top: 15px;
  display: block;
}
</style>

<style>
  .vjs-paused .vjs-big-play-button,
.vjs-paused.vjs-has-started .vjs-big-play-button {
    display: block;
}
</style>