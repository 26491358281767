<template>
  <div class="index" id="indexTop">
    <div class="index-content flex flex-direction align-center">
      <div class="index-search flex justify-end align-center">
        <div class="search-left flex justify-center align-center">
          <div class="search-video" @click="getMoreList('1','0')">视频教程</div>
          <div class="search-video" @click="goClassWord">护肤知识</div>
        </div>
        <div class="search-right flex justify-between align-center">
          <input type="text" class="search-input-wrap" v-model="searchText" placeholder="输入产品名称查找视频" maxlength="100" @keyup.enter="searchHandle">
          <img src="../../assets/search.png" class="search-img" @click="searchHandle" alt="">
        </div>
      </div>
      <div class="content-produce">
        <div style="width: 100%;" v-if="produceList">
          <video id="myVideo" class="video-js video-play" controls preload="auto" :poster="videoPoster" data-setup="">
            <!-- <source :src="videoSrc" type="video/mp4" /> -->
          </video>
        </div>
        <div class="produce-wrap flex flex-wrap align-center" v-if="produceList">
          <div class="produce-block flex flex-direction align-center" :class="(index + 1) % 4 == 0 ? 'no-right-margin' : ''" v-for="(item, index) in produceList" :key="index">
            <img :src="item.cover_pic" class="produce-img" alt="">
            <div class="produce-title">{{ item.title }}</div>
            <!-- <div class="produce-title-tips">{{ item.title_tips}}</div> -->
            <div class="go-img-text-block flex justify-center align-center">
              <img src="../../assets/produce/hand.png" class="go-hand-img" alt="">
              <div class="produce-go flex justify-center align-center" @click="goClassVideo(item.url, item.cover_pic, item.title)"><span class="go-top10-info-text">点击学习使用方法</span></div>
            </div>
          </div>
        </div>
        <div class="produce-wrap" style="height:100px;line-height:100px;text-align:center;" v-else>没有查询到商品</div>
      </div>
      <div class="page-block">
        <el-pagination layout="prev, pager, next" :page-count="pageCount" @current-change="pageCurrentChange" @prev-click="prevPage" @next-click="nextClick"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { getVideoClass } from '@/api';

export default {
  name: "CompanyClass",

  data(){
    return {
      searchText: "", // 搜索框内容
      videoSrc: "", // 视频路径
      videoPoster: "", // 视频封面
      pageCount: 0,
      produceList: [],
      title: "",
    }
  },

  mounted() {
    this.initVideo();
  },

  created(){
    this.id = this.$route.query.id;
    this.$emit("isClass","class")
    this.cat_id = 2;
    this.page = 1;
    this.getMoreList();
  },

  metaInfo(){
    return {
      title: this.title + "_春之唤官方网站",
    }
  },

  methods: {
    initVideo() {
      //初始化视频方法
      let myPlayer = this.$video(myVideo, { 
        controls: true, //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。 
        autoplay: true, //自动播放属性,muted:静音播放
        preload: "auto", //建议浏览器是否应在加载元素后立即开始下载视频数据。
      });
    },

    // 进入教学视频
    goClassVideo(url, poster, title){
      // console.log(url)
      this.videoSrc = url;
      this.videoPoster = poster;
      this.title = title;
      let myPlayer = this.$video(myVideo);
      // console.log(myPlayer);
      myPlayer.src({
        src: this.videoSrc
      })
    },

    // 进入教学文章
    goClassWord(){
      router.replace({
        path: "/classWord"
      })
    },

    // 产品列表
    async getMoreList(pages,keytext){
      if(pages) {
        this.page = pages
      }
      if(keytext == '0') {
        this.searchText = ''
      }
      let data = await getVideoClass({
        page: this.page,
        searchkey: this.searchText,
        cat_id: this.cat_id,
        limit: "10",
      })
      this.pageCount = data.total_page;
      this.produceList = data.list;
      this.videoSrc = data.list[0].url;
      this.videoPoster = data.list[0].cover_pic;
      this.title = data.list[0].title;
      let myPlayer = this.$video(myVideo);
      myPlayer.src({
        src: data.list[0].url
      })
    },

    // 回车搜索
    searchHandle(event) {
      if (this.searchText === "") {
        // console.log("null: " + this.searchText)
      } else {
        this.page = 1;
        this.getMoreList();
        // console.log(this.searchText)
      }
    },

    // 页码发生改变时
    pageCurrentChange(val){
      this.page = val;
      this.getMoreList();
    },

    // 上一页
    prevPage(val){
      // console.log(val)
    },

    // 下一页
    nextClick(val){
      // console.log(val)
    },
  },
}
</script>

<style scoped>
.index {
  width: 100%;
  height: 100%;
  background-color: #eafcfc;
}

.banner-img {
  width: 100%;
  position: relative;
  z-index: 1;
  display: block;
}

.index-content {
  /* width: 75%; */
  width: 1200px;
  margin-top: 70px;
}

.index-search {
  width: 100%;
  margin-right: -172px;
}

.search-left {
  /* width: 52%; */
  /* width: 740px; */
  width: 59.5%;
  height: 45px;
  padding: 10px;
  border-bottom: 2px solid #034b3d;
}

.search-video {
  width: 100px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  font-size: 14px;
  color: #034b3d;
  border: 2px solid #034b3d;
  margin: 0 30px;
  cursor: pointer;
}

.search-right {
  height: 30px;
  border-right: 2px solid #a5a6aa;
  margin-left: 40px;
}

.search-input-wrap {
  width: 200px;
  height: 25px;
  line-height: 25px;
  text-align: left;
  padding: 0 10px;
}

.search-img {
  width: 25px;
  height: 25px;
  margin-left: 20px;
  margin-right: 10px;
}

.content-produce {
  /* width: 55.2%; */
  width: 800px;
  /* width: 660px; */
}

.video-play {
  width: 100%;
  height: 400px;
  margin-top: 30px;
}

.produce-wrap {
  width: 100%;
  margin-top: 20px;
}

.produce-block {
  /* width: 150px; */
  width: 176px;
  margin-bottom: 40px;
  margin-right: 32px;
}

.produce-block.no-right-margin {
  margin-right: 0;
}

.produce-img {
  width: 176px;
  height: 176px;
}

.produce-title {
  width: 100%;
  height: 45px;
  line-height: 22px;
  text-align: center;
  font-size: 14px;
  margin-top: 5px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.produce-title-tips {
  text-align: left;
  color: #a7a7a7;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.go-img-text-block {
  width: 100%;
  height: 20px;
  margin-top: 12px;
}

.go-hand-img {
  width: 23px;
  height: 20px;
  margin-right: 8px;
}

.produce-go {
  width: 110px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 10px;
  color: #818181;
  border: 1px solid #818181;
  cursor: pointer;
  position: relative;
}

.produce-go:after{
  position: absolute;
  content: '';
  height: 20px;
  width: 0;
  left: 0;
  top: 0;
  background: #034c3b;
  transition: width 0.3s;
}

.go-top10-info-text {
  position: relative;
  z-index: 1;
}

.produce-go:hover {
  color: #ffffff;
}

.produce-go:hover:after {
  width: 110px;
}

.page-block {
  width: 100%;
  height: 40px;
  margin-top: 10px;
  background: none;
}
</style>

<style>
.page-block .el-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}

.page-block .el-pagination button {
  background: none;
}

.page-block .el-pager li {
  background: none;
}

.page-block .el-dialog, .el-pager li {
  background: none;
}

.page-block .el-pagination button:disabled {
  background: none;
}
</style>