<template>
  <div class="index">
    <div class="page-top flex justify-center align-center" :class="{ background: isbackground }">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" router text-color="#000000" >
        <el-menu-item index="/homePage">首页</el-menu-item>
        <el-submenu index="/companyAbout">
          <template slot="title">关于春之唤</template>
          <el-menu-item index="/companyAbout">品牌故事</el-menu-item>
          <el-menu-item index="/companyGrass">探索本草嫩肤</el-menu-item>

        </el-submenu>
        <el-menu-item index="/companyProduce">全系产品</el-menu-item>
        <el-submenu index="/companyClass">
          <template slot="title">产品使用</template>
          <el-menu-item index="/companyClass">视频教程</el-menu-item>
          <el-menu-item index="/classWord">护肤知识</el-menu-item>
        </el-submenu>
        <el-menu-item index="/companyHonor">品牌荣誉</el-menu-item>
        <el-menu-item index="/companyActivity">品牌动态</el-menu-item>
      </el-menu>
      <!-- <div class="top-title" :class="{ tabBottom: isIndex }" @click="goIndex">首页</div>
      <div class="top-title" :class="{ tabBottom: isAbout }" @click="goAbout">关于春之唤</div>
      <div class="top-title" :class="{ tabBottom: isProduce }" @click="goProduce">全系产品</div>
      <div class="top-title" :class="{ tabBottom: isClass }" @click="goClass">产品使用</div> 
      <div class="top-title" :class="{ tabBottom: isHonor }" @click="goActive">品牌动态</div>
      <div class="top-title-noright" :class="{ tabBottom: isVip }" @click="goVipCenter">会员中心</div> -->
    </div>
    <router-view @isIndex="changeTopTab" @isAbout="changeTopTab" @isProduce="changeTopTab" @isClass="changeTopTab" @isHonor="changeTopTab" @isVip="changeTopTab" @isOther="changeTopTab"></router-view>
    
    <!-- <div class="go-top" @click="goBackTop">
      <img src="../assets/back_top.png" class="top-img" alt="" />
    </div> -->

    <div class="page-footer flex flex-direction align-center">
      <div class="flex justify-center align-center">
        <div class="footer-left flex flex-direction align-start">
          <div class="footer-left-title">快捷导航</div>
          <div class="footer-left-link flex flex-direction align-end">
            <div class="footer-left-link-one flex justify-end align-center">
              <div class="footer-link-title" @click="goIndex">首页</div>
              <div class="footer-link-title" @click="goAbout">了解春之唤</div>
              <div class="footer-link-title" @click="goProduce">全系产品</div>
            </div>
            <div class="footer-left-link-one flex justify-end align-center">
              <div class="footer-link-title" @click="goGrass">探索本草嫩肤</div>
              <div class="footer-link-title" @click="goHonor">品牌荣誉</div>
            </div>
          </div>
          <div class="footer-left-phone flex align-center"></div>
          <!--
          <div class="footer-left-phone flex align-center">联系我们：020-22326226 
            <div style="margin-left: 20px;">400-0666816</div>
          </div>
          -->
        </div>
        <div class="footer-line"></div>
        <div class="footer-right flex justify-between align-center">
          <!-- <div class="footer-img-block flex flex-direction align-center">
            <img src="../assets/home/bottom_01.gif" class="footer-code" alt="" />
            <div class="footer-img-text">美容顾问老师</div>
          </div> -->
          <div class="footer-img-block flex flex-direction align-center">
            <img src="../assets/home/bottom_04.gif" class="footer-code" alt="" />
            <div class="footer-img-text">快手号</div>
          </div>
          <div class="footer-img-block flex flex-direction align-center" style="margin-right:0;">
            <img src="../assets/home/bottom_03.jpg" class="footer-code" alt="" />
            <div class="footer-img-text">公众号</div>
          </div>
        </div>
      </div>
      <div class="bottom-banquan flex justify-center align-center">
        <div>©Copyright 2021~2022 春之唤</div>
        <div style="margin-left:10px;cursor: pointer;" @click="goOutLink">浙ICP备2021004403号-2</div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "Index",

  data() {
    return {
      isbackground: false,
      // tabBottom: false,
      isIndex: false, //是否是主页
      isAbout: false, // 是否是关于春之唤
      isProduce: false, // 是否是全系产品
      isClass: false, // 是否是产品使用
      isHonor: false, // 是否是活动
      isVip: false, // 是否是会员中心
      activeIndex: "/homePage",
    };
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll); // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
  },

  created() {
    this.checkMobile();
    // this.goBackTop();
    
  },

  methods: {
    // 判断是否是移动端
    checkMobile() {
      this.flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      // console.log(this.flag);
      if (this.flag === null) {
        // this.isPC = true;
        console.log("pc端");
        // this.rightNavShow = true;
        // let url = window.location.protocol + "//"+window.location.host + '/teach'; //我的移动端页面是在h5里面的所以这里添加了后面这一段'/h5/index.html'
        // window.location.href = url; //如果有实际移动端域名网址的也可以忽略上面一段，直接输入移动端网址进行跳转就可以了
      } else {
        // this.isPC = false;
        console.log("移动端");
        // this.rightNavShow = false;
        window.location.href = 'http://m.chunzhihuan.com'; //如果有实际移动端域名网址的也可以忽略上面一段，直接输入移动端网址进行跳转就可以了
      }
    },

    // 子路由页面传来的参数
    changeTopTab(data){
      // console.log(data)
      if(data === "index") {
        this.isIndex = true; //是否是主页
        this.isAbout = false;// 是否是关于春之唤
        this.isProduce = false; // 是否是全系产品
        this.isClass = false; // 是否是产品使用
        this.isHonor = false; // 是否是活动
        this.isVip = false; // 是否是会员中心
        this.activeIndex = "/homePage";
      } else if(data === "about") {
        this.isIndex = false; //是否是主页
        this.isAbout = true;// 是否是关于春之唤
        this.isProduce = false; // 是否是全系产品
        this.isClass = false; // 是否是产品使用
        this.isHonor = false; // 是否是活动
        this.isVip = false; // 是否是会员中心
        this.activeIndex = "/companyAbout";
      } else if(data === "produce") {
        this.isIndex = false; //是否是主页
        this.isAbout = false;// 是否是关于春之唤
        this.isProduce = true; // 是否是全系产品
        this.isClass = false; // 是否是产品使用
        this.isHonor = false; // 是否是活动
        this.isVip = false; // 是否是会员中心
        this.activeIndex = "/companyProduce";
      } else if(data === "class") {
        this.isIndex = false; //是否是主页
        this.isAbout = false;// 是否是关于春之唤
        this.isProduce = false; // 是否是全系产品
        this.isClass = true; // 是否是产品使用
        this.isHonor = false; // 是否是活动
        this.isVip = false; // 是否是会员中心
        this.activeIndex = "/companyClass";
      } else if(data === "honor") {
        this.isIndex = false; //是否是主页
        this.isAbout = false;// 是否是关于春之唤
        this.isProduce = false; // 是否是全系产品
        this.isClass = false; // 是否是产品使用
        this.isHonor = true; // 是否是荣耀
        this.isVip = false; // 是否是会员中心
        // this.activeIndex = "/companyActivity";
        this.activeIndex = "/companyHonor";
      } else if(data === "vip") {
        this.isIndex = false; //是否是主页
        this.isAbout = false;// 是否是关于春之唤
        this.isProduce = false; // 是否是全系产品
        this.isClass = false; // 是否是产品使用
        this.isHonor = false; // 是否是活动
        this.isVip = true; // 是否是会员中心
        this.activeIndex = "/companyVip";
      } else if (data === "other") {
        this.isIndex = false; //是否是主页
        this.isAbout = false;// 是否是关于春之唤
        this.isProduce = false; // 是否是全系产品
        this.isClass = false; // 是否是产品使用
        this.isHonor = false; // 是否是活动
        this.isVip = false; // 是否是会员中心
        this.activeIndex = "";
      }
      this.goBackTop();
      this.checkMobile();
    },

    // 进入首页
    goIndex() {
      router.replace({
        path: "/homePage",
      });
      // this.goBackTop();
    },

    // 进入关于春之唤
    goAbout() {
      router.replace({
        path: "/companyAbout",
      });
      // this.goBackTop();
    },

    // 进入探寻本草嫩肤
    goGrass() {
      router.replace({
        path: "/companyGrass",
      });
      // this.goBackTop();
    },

    // 进入全系产品
    goProduce() {
      router.replace({
        path: "/companyProduce",
      });
      // this.goBackTop();
    },

    // 进入产品使用
    goClass(){
      router.replace({
        path: "/companyClass"
      });
      // this.goBackTop();
    },

    // 进入品牌动态
    goActive() {
      router.replace({
        path: "/companyActivity",
      });
      // this.goBackTop();
    },
    
    // 进入品牌动态
    goHonor() {
      router.replace({
        path: "/companyHonor",
      });
      // this.goBackTop();
    },

    // 进入会员中心
    goVipCenter(){
      router.replace({
        path: "/companyVip",
      });
      // this.goBackTop();
    },

    // 进入浙江外链官网
    goOutLink(){
      window.open('https://beian.miit.gov.cn/');
    },

    // 页面滚动
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; // 滚动条偏移量
        // console.log(scrollTop)
      if (scrollTop > 30) {
        this.isbackground = true;
      } else {
        this.isbackground = false;
      }
    },

    // 回到顶部
    goBackTop() {
      // console.log("ggggggg");
      // console.log(document.getElementsByClassName("index"))
      document.documentElement.scrollTop = 0;
      // document.getElementsByClassName("index1")[0].scrollTop = 0;
    },
  },
};
</script>

<style scoped>
.index {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-top {
  width: 100%;
  height: 45px;
  line-height: 45px;
  font-size: 18px;
  position: fixed;
  top: 0;
  padding: 10px 20% 0 20%;
  z-index: 999;
}

.page-top:hover {
  background: #ffffff;
  color: #000000;
}

.top-title {
  cursor: pointer;
  margin-right: 70px;
}

.top-title-noright {
  cursor: pointer;
  margin-right: none;
}

.background {
  background: #ffffff;
}

.tabBottom {
  color: #034b3d;
  border-bottom: 2px solid #034b3d;
}

.page-footer {
  width: 100%;
  height: 340px;
  margin-top: 20px;
  padding-bottom: 30px;
  background-color: #034b3d;
}

.footer-left {
  width: 320px;
  color: #ffffff;
  font-size: 14px;
}

.footer-left-title {
  width: 100%;
  height: 35px;
  line-height: 35px;
  text-align: left;
}

.footer-link-title {
  width: 110px;
  height: 35px;
  line-height: 35px;
  text-align: left;
  cursor: pointer;
}

.footer-line {
  width: 1px;
  height: 200px;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 0 15px;
}

.footer-left-phone {
  width: 100%;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
}

.footer-right {
  margin-left: 40px;
}

.footer-img-block {
  margin-right: 40px;
}

.footer-code {
  width: 80px;
  height: 80px;
}

.footer-img-text {
  margin-top: 5px;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  color: #ffffff;
}

.go-top {
  width: 50px;
  height: 50px;
  overflow: hidden;
  position: fixed;
  right: 0;
  bottom: 25%;
  z-index: 1;
}

.top-img {
  width: 50px;
  height: 50px;
}

.bottom-banquan {
  width: 100%;
  height: 60px;
  text-align: center;
  line-height: 60px;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

@media (max-width: 767px) {
  .page-top {
    width: 100%;
    height: 45px !important;
    line-height: 45px;
    font-size: 12px !important;
    position: fixed;
    top: 0;
    padding: 10px 0 0 0;
    z-index: 999;
  }

  .page-top .top-title {
    cursor: pointer;
    margin-right: 20px !important;
  }
}
</style>

<style>
.page-top .el-menu.el-menu--horizontal {
  border-bottom: none !important;
}

.page-top .el-submenu__title {
  font-size: 18px;
  height: 45px !important;
  line-height: 45px !important;
  padding: 0 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-top .el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
  border: none;
}

.page-top .el-menu--horizontal>.el-submenu .el-submenu__title {
  font-size: 18px;
  color: #303133 !important;
}

.page-top .el-menu--popup .el-menu-item {
  text-align: center;
}

.page-top .el-menu--horizontal>.el-submenu {
  display: flex;
  justify-content: center;
  align-items: center;
  float: none !important;
}

.page-top .el-menu-demo {
  width: 50%;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  color: #000000;
}

.page-top .el-menu--horizontal>.el-menu-item {
  color: #000000;
  font-size: 18px;
  height: 45px;
  line-height: 45px;
}

.el-menu-item.demo:hover{
  background: none !important;
}

.page-top .el-menu-item.is-active {
  background: none !important;
  border-bottom: 2px solid #034b3d !important;
  /* color: #034b3d !important; */
}

.page-top .el-submenu__title.is-active {
  background: none !important;
  /* color: #034b3d !important; */
}

.page-top .el-menu--horizontal>.el-submenu.is-active {
  background: none !important;
  border-bottom: 2px solid #034b3d !important;
  /* color: #034b3d !important; */
}

.page-top .el-submenu__icon-arrow {
  display: none;
}



/* .el-menu--popup {
  width: auto !important;
  min-width: none !important;
} */
</style>
