<template>
  <div class="index" id="indexTop">
    <img src="../../assets/activity/gongyi_banner.jpg" alt="" class="banner-img" />
    <div class="activity-block" v-if="storyList">
      <div class="activity-wrap flex justify-between align-center" v-for="(item, index) in storyList" :key="index" @click="goDuty(item.id)">
        <img :src="item.cover_pic" alt="" class="activity-img" />
        <div class="activity-text">
          <div class="activity-title">{{ item.title }}</div>
          <!-- <div class="activity-title" v-if="item.title_tips">{{ item.title_tips }}</div> -->
          <div class="activity-content">{{ item.digest }}</div>
        </div>
      </div>
    </div>
    <div class="activity-block" style="height:100px;line-height:100px;text-align:center;" v-else>
      没有查询到数据
    </div>
    <div class="page-block">
      <el-pagination layout="prev, pager, next" :page-count="pageCount" @current-change="pageCurrentChange" @prev-click="prevPage" @next-click="nextClick"></el-pagination>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { getNewsList } from '@/api';

export default {
  name: "Active",

  data(){
    return {
      storyList: [],
      cat_id: 0,
      pageCount: 0,
      page: 1,
    }
  },

  // watch: {
  //   '$route' (to,from){
  //     // this.cat_id = to.query.id;
  //     this.cat_id = 3092;
  //     this.page = 1;
  //     this.caseList = [];
  //     this.getMoreList()
  //   }
  // },

  created(){
    // this.$emit("isActive","active")
    this.$emit("isAbout","about")
    // this.cat_id = 3092;
    // this.page = 1;
    this.getMoreList();
  },

  metaInfo(){
    return {
      title: "品牌动态-春之唤官方网站",
    }
  },

  methods: {
    // 进入企业社会责任
    goDuty(id){
      router.replace({
        path: "/companyActiveInfo",
        query: { id: id },
      });
    },

    // 品牌动态列表
    async getMoreList(){
      let data = await getNewsList({
        page: this.page,
      })
      this.pageCount = data.total_page;
      this.storyList = data.list;
    },

    // 页码发生改变时
    pageCurrentChange(val){
      // console.log(val)
      this.page = val;
      this.getMoreList();
    },

    // 上一页
    prevPage(val){
      // console.log(val)
    },

    // // 下一页
    nextClick(val){
      // console.log(val)
    },
  },
}
</script>

<style scoped>
.index {
  width: 100%;
  height: 100%;
}

.banner-img {
  width: 100%;
  position: relative;
  z-index: 1;
  display: block;
}

.activity-block {
  /* width: 100%; */
  /* margin-top: 30px; */
  /* width: 65%; */
  width: 1100px;
  padding-bottom: 50px;
  /* padding: 30px; */
}

.activity-wrap {
  /* width: 150px; */
  width: 100%;
  /* height: 120px; */
  padding: 30px 0 50px 0;
  margin-top: 15px;
  border-bottom: 1px solid #d8d8d8;
  cursor: pointer;
}

.activity-img {
  width: 48%;
  display: block;
  /* margin-bottom: 10px; */
}

.activity-text {
  width: 48%;

}

.activity-title {
  width: 100%;
  height: 60px;
  line-height: 30px;
  font-size: 18px;
  text-align: left;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.activity-content {
  width: 100%;
  line-height: 30px;
  font-size: 16px;
  text-align: left;
  color: #9e9d9d;
}

.page-block {
  width: 100%;
  height: 40px;
  margin-top: 10px;
  background: none;
}

.page-block {
  /* padding-bottom: 30px; */
}
</style>

<style>
.page-block .el-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}

.page-block .el-pagination button {
  background: none;
}

.page-block .el-pager li {
  background: none;
}

.page-block .el-dialog, .el-pager li {
  background: none;
}

.page-block .el-pagination button:disabled {
  background: none;
}
</style>