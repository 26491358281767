import { render, staticRenderFns } from "./classWord.vue?vue&type=template&id=97b7232e&scoped=true&"
import script from "./classWord.vue?vue&type=script&lang=js&"
export * from "./classWord.vue?vue&type=script&lang=js&"
import style0 from "./classWord.vue?vue&type=style&index=0&id=97b7232e&scoped=true&lang=css&"
import style1 from "./classWord.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97b7232e",
  null
  
)

export default component.exports