<template>
  <div class="index flex flex-direction align-center" id="indexTop">
    <img src="../../assets/faceTest/test_banner.jpg" alt="" class="banner-img" />
    <img src="../../assets/faceTest/test_01.jpg" alt="" class="content-img" @click="goFace" />
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "Face",

  data(){
    return {
      
    }
  },

  created(){
    this.$emit("isOther","other")
  },

  metaInfo(){
    return {
      title: "春之唤官方网站",
    }
  },

  methods: {
    // 进入皮肤测试外链
    goFace(){
      window.location.href = 'https://api.skinrun.cn/html/ai/index.html?token=pJFDg83DizVEpetamyg49hsrrYZUfDKw%2B%2FqAlwUUPTNfsQwF2zVmdg';
    },
  },
}
</script>

<style scoped>
.index {
  width: 100%;
  height: 100%;
}

.banner-img {
  width: 100%;
  position: relative;
  z-index: 1;
  display: block;
}

.content-img {
  width: 40%;
  margin-top: 100px;
  padding-bottom: 100px;
  cursor: pointer;
}
</style>