<template>
  <div class="index flex flex-direction align-center" id="indexTop">
    <div class="top-swiper">
      <img src="../../assets/honor/topbanner.png" alt="" class="banner-img" />
    </div>
    <div class="yearTab" >

          <div  v-for="(item, index) in year_list" :key="index" :class="{selected: year ==item}" class="inline-div"  @click="selectyear(item)" > {{item}}年</div>

    </div>


    <div class="honor-content flex flex-direction align-center" >
      <div class="honor-year">{{year}}年</div>
      <div class="honor-content-wrap flex flex-direction align-center" >
        <div class="honor-line"></div>
        <div class="honor-content-tree flex flex-direction justify-center align-center" v-for="(item, index) in honor_list" :key="index">

          <div class="flex justify-center align-center" style="margin-top: 40px;" v-if="(index+1)%2==0?false:true">
            <div class="honor-content-left flex align-center"></div>
            <div class="honor-center-month">{{ item.month }}月</div>
            <div class="honor-content-right flex align-center">
              <div class="honor-right-block flex align-center">
                <div class="honor-right-line"></div>
                <div class="honor-right-block-wrap flex justify-center align-center">
                  <div class="honor-right-block-in"></div>
                </div>
              </div>
              <div class="honor-right-one" v-html="item.remark"></div>
            </div>
          </div>

          <div class="flex justify-center align-center" style="margin-top: 10px;" v-if="(index+1)%2==0?true:false">
            <div class="honor-content-left flex align-center">
              <div class="honor-left-two"  v-html="item.remark"></div>
              <div class="honor-left-block">
                <div class="honor-left-block-top flex justify-center align-center">
                  <div class="honor-left-block-top-in"></div>
                </div>
                <div class="honor-left-line"></div>
              </div>
            </div>
            <div class="honor-center-month">{{ item.month }}月</div>
            <div class="honor-content-right"></div>
          </div>


        </div>



      </div>
    </div>



  </div>
</template>

<script>
import router from "@/router";
import {getHome, getHonor} from '@/api';

export default {
  name: "Honor",

  data(){
    return {
      year:2023,
      honor_list:[],
      year_list:[]
    };
  },

  created(){
    this.$emit("isHonor","honor")
    this.getHonorInfo();
    this.year2022Status=true;//默认当前年进入展示
  },

  metaInfo(){
    return {
      title: "品牌荣誉-春之唤官方网站",
    }
  },

  methods: {
    selectyear(year){
      this.year = year;
      console.log("year===="+this.year);
      this.getHonorInfo();
    },

    // 获取首页信息
    async getHonorInfo(){
      let data = await getHonor({
        termType: "PC",
        year:this.year,
      });
      this.honor_list = data.honor_list;
      this.year_list = data.year_list;
    },
    // 富文本特殊符号解析
    showHtml(str){
      return str
          .replace(str ? /&(?!#?\w+;)/g : /&/g, '&amp;')
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&quot;/g, "\"")
          .replace(/&#39;/g, "'")
          .replace(/&amp;nbsp;/g, "\u3000")
    },
  },
}
</script>

<style scoped>
.index {
  width: 100%;
  height: 100%;
}

.yearTab{
  display: flex;
  width: auto;
}

.yearTab .inline-div  {
  display: inline-block;
  margin-top: 50px;
  margin: 20px;
  padding: 5px 24px;
  color: #ffffff;
  background: #e3e3e3;
  border-radius: 30px;
  font-size: 24px;
  font-weight: 600;
}

.yearTab div.selected{
  background: #034b3d;
}

.top-swiper {
  width: 100%;
  position: relative;
  z-index: 1;
}

.banner-img {
  width: 100%;
  position: relative;
  z-index: 1;
  display: block;
}

.honor-content {
  width: 1000px;
  height: 100%;
  margin-top: 50px;
  /* background: red; */
}

.honor-year {
  padding: 5px 24px;
  color: #ffffff;
  background: #034b3d;
  border-radius: 30px;
  font-size: 24px;
  font-weight: 600;
}

.honor-content-wrap {
  position: relative;
  margin-top: 10px;
}

.honor-line {
  width: 1px;
  height: 100%;
  background: #a6b7b1;
  position: absolute;
  top: 0;
}

.honor-content-tree {
  width: 100%;
  position: relative;
  z-index: 1;
}

.honor-center-month {
  width: 96px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  border-radius: 50px;
  background: #034b3d;
  color: #ffffff;
  font-size: 18px;
}

.honor-content-left, .honor-content-right {
  width: 440px;
  height: auto;
  /* background: blue; */
}

.honor-left-two, .honor-left-one, .honor-right-two, .honor-right-one {
  width: 360px;
  text-align: left;
  background-color: #f7fffc;
  color: #0e0e0e;
  font-size: 16px;
  line-height: 20px;
  border: 1px dashed #6e8a81;
  /* margin: 24px 0; */
  padding: 10px;
}

.honor-right-block {
  width: 80px;
  margin-left: -10px;
  position: relative;
}

.honor-left-block {
  width: 80px;
  margin-right: -10px;
  position: relative;
}

.honor-right-line {
  width: 100%;
  height: 1px;
  background-color: #034b3d;
}

.honor-left-line {
  width: 100%;
  height: 1px;
  background-color: #034b3d;
}

.honor-right-block-wrap {
  width: 16px;
  height: 16px;
  background-color: #a9b9b4;
  position: absolute;
  right: -8px;
  border-radius: 50%;
}

.honor-left-block-two {
  width: 80px;
  height: 80px;
  border-right: 1px solid #034b3d;
  border-top: 1px solid #034b3d;
  border-bottom: 1px solid #034b3d;
  position: relative;
  margin-right: -10px;
}

.honor-right-block-two {
  width: 80px;
  height: 110px;
  border-left: 1px solid #034b3d;
  border-top: 1px solid #034b3d;
  border-bottom: 1px solid #034b3d;
  position: relative;
  margin-left: -10px;
}

.honor-left-block-top {
  width: 16px;
  height: 16px;
  background-color: #a9b9b4;
  position: absolute;
  top: -8px;
  left: -8px;
  border-radius: 50%;
}

.honor-left-block-bottom {
  width: 16px;
  height: 16px;
  background-color: #a9b9b4;
  position: absolute;
  bottom: -8px;
  left: -8px;
  border-radius: 50%;
}

.honor-right-block-top {
  width: 16px;
  height: 16px;
  background-color: #a9b9b4;
  position: absolute;
  top: -8px;
  right: -8px;
  border-radius: 50%;
}

.honor-right-block-bottom {
  width: 16px;
  height: 16px;
  background-color: #a9b9b4;
  position: absolute;
  bottom: -8px;
  right: -8px;
  border-radius: 50%;
}

.honor-left-block-top-in, .honor-right-block-in {
  width: 8px;
  height: 8px;
  background-color: #034b3d;
  border-radius: 50%;
}
</style>