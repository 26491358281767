<template>
  <div class="index" id="indexTop">
   <img src="../../assets/classWord/word_benner.png" alt="" class="banner-img" />
    <div class="activity-block">
      <div v-if="classList">
        <div class="activity-wrap flex justify-between align-center" v-for="(item, index) in classList" :key="index" @click="goClassInfo(item.id)">
          <img :src="item.cover_pic" alt="" class="activity-img" />
          <div class="activity-text">
            <div class="activity-title">{{ item.title }}</div>
            <div class="activity-content">{{ item.digest }}</div>
          </div>
        </div>
      </div>
      <div v-else style="height:100px;line-height:100px;text-align:center;">
        没有查询到商品
      </div>
      <div class="page-block" style="margin-top: 20px;">
        <el-pagination layout="prev, pager, next" :page-count="pageCount" @current-change="pageCurrentChange" @prev-click="prevPage" @next-click="nextClick"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { getVideoClass } from '@/api';

export default {
  name: "ClassWord",

  data(){
    return {
      classList: [],
      pageCount: 0,
      page: 1,
    }
  },

  created(){
    this.$emit("isClass","class")
    this.getMoreList()
  },

  metaInfo(){
    return {
      title: "护肤知识-春之唤官方网站",
    }
  },

  methods: {
    // 品牌动态列表
    async getMoreList(){
      let data = await getVideoClass({
        page: this.page,
        cat_id: 1,
      })
      this.pageCount = data.total_page;
      this.classList = data.list;
    },

    // 进入课堂详情
    goClassInfo(id){
      router.replace({
        path: "/classWordInfo",
        query: { id: id },
      })
    },

    // 页码发生改变时
    pageCurrentChange(val){
      // console.log(val)
      this.page = val;
      this.getMoreList();
    },

    // 上一页
    prevPage(val){
      // console.log(val)
    },

    // // 下一页
    nextClick(val){
      // console.log(val)
    },
  },
}
</script>

<style scoped>
.index {
  width: 100%;
  height: 100%;
}

.banner-img {
  width: 100%;
  position: relative;
  z-index: 1;
  display: block;
}

.activity-block {
  /* width: 100%; */
  /* margin-top: 30px; */
  width: 50%;
  padding-bottom: 50px;
  /* padding: 30px; */
}

.activity-wrap {
  /* width: 150px; */
  width: 100%;
  /* height: 120px; */
  padding: 30px 0 50px 0;
  margin-top: 15px;
  border-bottom: 1px solid #d8d8d8;
  cursor: pointer;
}

.activity-img {
  width: 48%;
  display: block;
  /* margin-bottom: 10px; */
}

.activity-text {
  width: 48%;

}

.activity-title {
  width: 100%;
  /* height: 25px; */
  line-height: 40px;
  font-size: 18px;
  text-align: left;
}

.activity-content {
  width: 100%;
  line-height: 30px;
  font-size: 16px;
  text-align: left;
  color: #9e9d9d;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>

<style>
.page-block .el-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}

.page-block .el-pagination button {
  background: none;
}

.page-block .el-pager li {
  background: none;
}

.page-block .el-dialog, .el-pager li {
  background: none;
}

.page-block .el-pagination button:disabled {
  background: none;
}
</style>