import http from '@/libs/axios';

const url_base = "/index.php?g=Api&";

// 首页
export const getHome = (params) => {return http.request({ url: url_base + 'm=GoodsService&a=getHome', params, method: 'get'}) }

//荣誉
export const getHonor = (params) => {return http.request({ url: url_base + 'm=GoodsService&a=getHonor', params, method: 'get'}) }

// 资讯列表
export const getNewsList = (params) => {return http.request({ url: url_base + 'm=NewsService&a=getNewsList', params, method: 'get' }) };

// 资讯详情
export const getNewsInfo = (params) => {return http.request({ url: url_base + 'm=NewsService&a=getNewsInfo', params, method: 'get' }) };

// 产品列表
export const getGoodsList = (params) => {return http.request({ url: url_base + 'm=GoodsService&a=getIndex', params, method: 'get' }) };

// top10列表
export const getTop10 = (params) => {return http.request({ url: url_base + 'm=GoodsService&a=getGoodsList', params, method: 'get' }) };

// 产品详情
export const getGoodsInfo = (params) => {return http.request({ url: url_base + 'm=GoodsService&a=getGoodsInfo', params, method: 'get' }) };

// 小课堂列表
export const getVideoClass = (params) => {return http.request({ url: url_base + 'm=NewsService&a=getCoursesList', params, method: 'get' }) };

// 护肤知识详情
export const getCoursesInfo = (params) => {return http.request({ url: url_base + 'm=NewsService&a=getCoursesInfo', params, method: 'get' }) };

// VIP顶部banner
export const getBannerList = (params) => {return http.request({ url: url_base + 'm=AppService&a=getBannerList', params, method: 'get' }) };

// VIP福利
export const getMemberList = (params) => {return http.request({ url: url_base + 'm=AppService&a=getMemberList', params, method: 'get' }) };