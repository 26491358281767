<template>
  <div class="index">
    <img :src="goods_info.big_image" alt="" class="banner-img" />
    <div class="index-content">
      <!-- <div class="produce-top flex align-center">
        <img :src="goods_info.big_image" class="produce-top-img" alt="">
        <div class="produce-right flex flex-direction justify-around">
          <div class="produce-right-title">{{ title }}</div>
          <div class="produce-right-block flex align-start">
            <div class="produce-right-block-tips">净含量</div>
            <div class="produce-right-block-info">{{ goods_info.spec_value }}</div>
          </div>
          <div class="produce-right-block flex align-start">
            <div class="produce-right-block-tips">产品功效</div>
            <div class="produce-right-block-info">{{ goods_info.description }}</div>
          </div>
          <div class="produce-right-block flex align-start">
            <div class="produce-right-block-tips">适用肤质</div>
            <div class="produce-right-block-info">{{ goods_info.material }}</div>
          </div>
        </div>
      </div> -->
      <div class="content-html" v-html="goodsInfo"></div>
      <div class="duty-content-next-pre flex justify-between align-center">
        <div class="pre-text-block">
          <div class="pre-text-block" v-if="goods_info.next_id" @click="preArtical(goods_info.next_id)">上一篇</div>
        </div>
        <div class="pre-text-block">
          <div class="pre-text-block" v-if="goods_info.pre_id" @click="preArtical(goods_info.pre_id)">下一篇</div>
        </div>
      </div>
      <!-- <img src="../../assets/produceInfo/produce_01.png" alt="" class="banner-img" />
      <img src="../../assets/produceInfo/produce_02.png" alt="" class="banner-img" style="margin-top: 50px;" />
      <img src="../../assets/produceInfo/produce_03.png" alt="" class="banner-img" /> -->
    </div>
    
  </div>
</template>

<script>
import router from "@/router";
import { getGoodsInfo }  from '@/api';

export default {
  name: "ProduceInfo",

  data(){
    return {
      title: "",
      goodsInfo: "", // 商品详情
      goods_info: "", // 顶部信息
    }
  },


  metaInfo(){
    return {
      title: this.title + "-产品-春之唤官方网站",
    }
  },

  created(){
    this.id = this.$route.query.id;
    
    this.$emit("isOther","other")
    this.getInfo();
  },

  methods: {
    // 获取产品详情
    async getInfo(){
      let data = await getGoodsInfo({
        goods_id: this.id,
      })
      // console.log(this.showHtml(data.content))
      this.goods_info = data;
      this.title = data.goods_title;
      this.goodsInfo = this.showHtml(data.content)
    },

    // 富文本特殊符号解析
    showHtml(str){
      return str
        .replace(str ? /&(?!#?\w+;)/g : /&/g, '&amp;')
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, "\"")
        .replace(/&#39;/g, "'")
        .replace(/&amp;nbsp;/g, "\u3000")
    },

    // 上下篇文章
    preArtical(id){
      this.id = id;
      router.replace({
        path: "/companyProduceInfo",
        query: { id: id },
      })
      this.getInfo();
      document.documentElement.scrollTop = 0;
    },
  },
}
</script>

<style scoped>
.index {
  width: 100%;
  height: 100%;
}

.index-content {
  width: 50%;
  margin-top: 100px;
  padding-bottom: 70px;
}

.banner-img {
  width: 100%;
  position: relative;
  z-index: 1;
  display: block;
}

.produce-top {
  width: 100%;
  min-height: 50px;
  margin-bottom: 30px;
  /* background-color: #eafcfc; */
}

.produce-top-img {
  width: 40%;
}

.produce-right {
  width: 54%;
  padding: 0 3%;
}

.produce-right-title {
  width: 100%;
  text-align: left;
  height: 30px;
  line-height: 30px;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

.produce-right-block {
  width: 100%;
  margin-bottom: 15px;
}

.produce-right-block-tips {
  /* padding: 0 15px; */
  width: 90px;
  text-align: center;
  height: 26px;
  line-height: 26px;
  background-color: #034c3b;
  color: #ffffff;
  font-size: 16px;
  margin-right: 10px;
}

.produce-right-block-info {
  line-height: 26px;
  font-size: 16px;
  color: #6d6d6d;
}

.content-html {
  width: 100%;
}

.content-html >>> img {
  width: 100%;
  display: block;
}

.duty-content-next-pre {
  width: 100%;
  height: 60px;
}

.pre-text-block {
  min-width: 80px;
  font-size: 18px;
  color: #034c3b;
  cursor: pointer;
  margin-top: 15px;
}
</style>