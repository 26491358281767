<template>
  <div class="index flex flex-direction align-center" id="indexTop">
    <div class="top-swiper">
      <el-carousel :height="swiperHeight + 'px'" :autoplay="true" :interval="2000" arrow="never">
        <el-carousel-item v-for="(item, index) in Banner" :key="index">
          <img :src="item.img" alt="" class="banner-img" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- <img src="../../assets/vip/vip_banner.jpg" alt="" class="banner-img" /> -->
    <div class="vip-tab flex justify-center align-center">
      <div class="vip-tab-block flex flex-direction justify-center align-center" @click="chooseTab(1)">
        <img src="../../assets/vip/vip_quan_01_1.png" class="vip-tab-img" alt="" v-if="tabNum == '1'">
        <img src="../../assets/vip/vip_quan_01.png" class="vip-tab-img" alt="" v-else>
        <div class="vip-tab-text" :class="tabNum == '1' ? 'is-choose' : ''">会员权益</div>
      </div>
      <div class="vip-tab-block flex flex-direction justify-center align-center" @click="chooseTab(2)">
        <img src="../../assets/vip/vip_quan_02_1.png" class="vip-tab-img" alt="" v-if="tabNum == '2'">
        <img src="../../assets/vip/vip_quan_02.png" class="vip-tab-img" alt="" v-else>
        <div class="vip-tab-text" :class="tabNum == '2' ? 'is-choose' : ''">粉丝团福利</div>
      </div>
      <div class="vip-tab-block flex flex-direction justify-center align-center" @click="chooseTab(3)">
        <img src="../../assets/vip/vip_quan_03_1.png" class="vip-tab-img" alt="" v-if="tabNum == '3'">
        <img src="../../assets/vip/vip_quan_03.png" class="vip-tab-img" alt="" v-else>
        <div class="vip-tab-text" :class="tabNum == '3' ? 'is-choose' : ''">积分兑换</div>
      </div>
      <div class="vip-tab-block flex flex-direction justify-center align-center" @click="chooseTab(4)">
        <img src="../../assets/vip/vip_quan_04_1.png" class="vip-tab-img" alt="" v-if="tabNum == '4'">
        <img src="../../assets/vip/vip_quan_04.png" class="vip-tab-img" alt="" v-else>
        <div class="vip-tab-text" :class="tabNum == '4' ? 'is-choose' : ''">生日礼物</div>
      </div>
      <div class="vip-tab-block flex flex-direction justify-center align-center" style="border-right: none;" @click="goFaceTest">
        <img src="../../assets/vip/vip_quan_05.png" class="vip-tab-img" alt="">
        <div class="vip-tab-text">皮肤测试</div>
      </div>
    </div>
    <div class="vip-choose-img">
      <div v-for="(item, index) in VipBanner" :key="index">
        <img :src="item.pc_img" alt="" class="vip-choose-img-block" v-if="item.id == tabNum">
      </div>
      <!-- <img src="../../assets/vip/vip_01.jpg" alt="" class="vip-choose-img-block" v-if="tabNum == '1'">
      <img src="../../assets/vip/vip_02.jpg" alt="" class="vip-choose-img-block" v-if="tabNum == '2'">
      <img src="../../assets/vip/vip_03.jpg" alt="" class="vip-choose-img-block" v-if="tabNum == '3'">
      <img src="../../assets/vip/vip_04.jpg" alt="" class="vip-choose-img-block" v-if="tabNum == '4'"> -->
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { getBannerList } from '@/api';
import { getMemberList } from '@/api';

export default {
  name: "companyVIP",

  data(){
    return {
      Banner: [],
      tabNum: "1",
      title: "",
      swiperHeight: 900,
      VipBanner: [],
    };
  },

  mounted(){
    this.winWidth();
    window.addEventListener("resize", this.winWidth,false)
  },

  created(){
      this.$emit("isVip","vip");
      this.getTopBanner();
      this.getMember();
  },

  metaInfo(){
    return {
      title: "春之唤官方网站",
    }
  },

  methods: {
    // 屏幕宽度
    winWidth(){
      var w = document.documentElement.clientWidth || document.body.clientWidth;
      this.swiperHeight = Math.round((900*w)/1920);
    },

    // 顶部banner
    async getTopBanner(){
      let data = await getBannerList({
        pos_id: "3"
      });
      this.Banner = data.banner_list;
    },

    // 会员权益
    async getMember(){
      let data = await getMemberList({});
      this.VipBanner = data.list;
      // console.log(data)
    },

    // 会员权益选择
    chooseTab(num){
      // console.log(num)
      this.tabNum = num
    },

    // 进入皮肤测试
    goFaceTest(){
      router.replace({
        path: "/faceTest"
      })
    },
  },
}
</script>

<style scoped>
.index {
  width: 100%;
  height: 100%;
}

.top-swiper {
  width: 100%;
  position: relative;
  z-index: 1;
}

.banner-img {
  width: 100%;
  position: relative;
  z-index: 1;
  display: block;
}

.vip-tab {
  width: 100%;
  height: 110px;
  background-color: #08484f;
}

.vip-tab-block {
  width: 150px;
  height: 80px;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.vip-tab-img {
  width: 40px;
  height: 40px;
}

.vip-tab-text {
  width: 100%;
  text-align: center;
  height: 20px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 5px;
  font-size: 14px;
}

.vip-tab-text.is-choose {
  color: #ffffff;
}

.vip-choose-img {
  width: 50%;
  padding: 50px 0;
}

.vip-choose-img-block {
  width: 100%;
}
</style>