<template>
  <div class="index flex flex-direction align-center" id="indexTop">
    <div class="top-swiper">
      <el-carousel :height="swiperHeight + 'px'" :autoplay="true" :interval="2000" arrow="never">
        <el-carousel-item v-for="(item, index) in Banner" :key="index">
          <img :src="item.img" alt="" class="banner-img" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="index-content">
      <div class="index-story flex justify-between align-center aos-init" data-aos="fade-up">
        <!-- <img src="../../assets/home/home_04.jpg" class="index-story-img" style="margin-right: 5px;cursor: pointer;" alt="" /> -->
        <div class="index-story-text flex flex-direction justify-between">
          <div class="index-story-text-eng">INOHERB</div>
          <div class="index-story-text-eng">STORY</div>
          <div class="index-story-text-title">品牌故事</div>
          <div class="index-story-text-content">2018年，春之唤品牌源传公元682年《千金翼方》此古法中“道地本草，春季采摘”的核心理念，以此创立本草护肤品牌。并以“北参南卉”等本草植物结合前沿生物科技，再现肌肤年轻之美！</div>
          <div class="index-go-more" @click="goAbout"><span class="index-go-more-text">了解更多</span></div>
        </div>
        <img src="../../assets/home/home_05.jpg" class="index-story-img" alt="" />
      </div>
      <div class="index-story flex align-center aos-init" data-aos="fade-up">
        <div class="index-grass-text">
          <div class="index-grass-text-eng">EXPLORE HERBAL</div>
          <div class="index-grass-text-eng">REJUVENATION</div>
          <div class="index-grass-text-title">探寻本草嫩肤</div>
          <div class="index-grass-text-tips">道地本草 春季采摘</div>
          <div class="index-grass-text-content">春之唤甄选道地本草植物，于春天万物生长时机采摘，此时经过了漫长冬季的养分储存，植物的生命力是最为旺盛，而春季阳光温暖、雨水充沛，植物有效成分含量高，质量最好。</div>
          <div class="index-grass-go" @click="goGrass"><span class="index-grass-go-more-text">探寻更多</span></div>
        </div>
        <img src="../../assets/home/home_06.jpg" class="index-grass" alt="" />
      </div>
      <div class="index-story flex justify-between align-center aos-init" data-aos="fade-up">
        <img src="../../assets/home/home_07.jpg" class="index-story-img" style="margin-right: 5px;cursor: pointer;" alt="" @click="goTop10" />
        <img src="../../assets/home/home_08.jpg" class="index-story-img" alt="" />
      </div>
      <div class="index-story flex justify-center align-center aos-init" data-aos="fade-up">
        <div class="index-line"></div>
        <div class="index-tips" @click="goTop10"><span class="go-top10-info-text">查看全部产品</span></div>
        <div class="index-line"></div>
      </div>
      <div class="index-story flex justify-between align-center aos-init" style="min-height: 360px;" data-aos="fade-up">
        <div class="index-three" :class="index == mouseNum ? 'mouseActive' : ''" v-for="(item, index) in heartList" :key="index" @click="goDuty(item.id)" @mouseenter="dutyMouse(index)" @mouseout="dutyMouseOut(index)">
          <img :src="item.cover_pic" class="index-three-img" alt="">
          <div class="index-three-title">{{ item.title }}</div>
          <!-- <div class="index-three-tips">{{ item.title_tips }}</div> -->
        </div>
      </div>
      <div class="index-story flex justify-center align-center aos-init" style="margin-bottom: 25px;" data-aos="fade-up">
        <div class="index-line"></div>
        <div class="index-tips" @click="goActivity"><span class="go-top10-info-text">查看更多动态</span></div>
        <div class="index-line"></div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { getHome } from '@/api';

export default {
  name: "Home",

  data() {
    return {
      keywords: "",
      description: "",
      Banner: [],
      heartList: [],
      swiperHeight: 900,
      mouseNum: 0,
    };
  },

  mounted(){
    this.winWidth();
    window.addEventListener("resize", this.winWidth,false)
  },

  created() {
    this.$emit("isIndex","index")
    this.getHomeInfo();
  },

  metaInfo(){
    return {
      title: "春之唤官方网站",
      meta: [
        { vmid: 'keywords', name: 'keywords', content: this.keywords },
        { vmid: 'description', name: 'description', content: this.description },
      ]
    }
  },

  methods: {
    // 屏幕宽度
    winWidth(){
      var w = document.documentElement.clientWidth || document.body.clientWidth;
      this.swiperHeight = Math.round((900*w)/1920);
      // console.log(this.swiperHeight)
      // var h = document.documentElement.clientHeight || document.body.clientHeight;
      // this.swiperHeight = Math.round(h * 0.9);
      // // console.log(this.swiperHeight)
      // console.log(h)
      // console.log(this.Banner[0].img)
    },

    // 获取首页信息
    async getHomeInfo(){
      let data = await getHome({
        termType: "PC"
      });
      this.Banner = data.banner_list;
      this.heartList = data.news_list;
      this.keywords = data.site_info.keywords;
      this.description = data.site_info.content;
    },

    // 进入品牌故事
    goAbout(){
      router.replace({
        path: "/companyAbout",
      });
    },

    // 进入草本嫩肤
    goGrass(){
      router.replace({
        path: "/companyGrass",
      });
    },

    // 进入Top10
    goTop10(){
      router.replace({
        path: "/companyTop10",
      });
    },

    // 进入全系产品
    goProduce(){
      router.replace({
        path: "/companyProduce",
      });
    },

    // 进入更多社会责任
    goActivity(){
      router.replace({
        path: "/companyActivity",
      });
    },

    // 进入企业社会责任
    goDuty(id){
      router.replace({
        path: "/companyActiveInfo",
        query: { id: id },
      });
    },

    // 社会责任模块鼠标移入
    dutyMouse(index){
      // console.log(index)
      this.mouseNum = index;
      // console.log("232445")

    },

    // 社会责任模块鼠标移出
    dutyMouseOut(index){
      // console.log(index)
    },
  },
};
</script>

<style scoped>
.index {
  width: 100%;
  height: 100%;
}

.top-swiper {
  width: 100%;
  position: relative;
  z-index: 1;
}

.banner-img {
  width: 100%;
  height: 100%;
}

.index-content {
  /* width: 65%; */
  width: 1100px;
}

.index-story {
  margin-top: 25px;
  height: 100%;
  position: relative;
}

.index-story-img {
  width: 50%;
  display: block;
}

.index-story-text {
  width: 46%;
  padding: 30px 2%;
  height: 100%;
  /* background: red; */
}

.index-story-text-eng {
  font-size: 50px;
  /* font-weight: 600; */
  line-height: 65px;
}

.index-story-text-title {
  margin: 30px 0;
  font-size: 30px;
  line-height: 40px;
}

.index-story-text-content {
  font-size: 18px;
  line-height: 30px;
  text-align: left;
  color: #404042;
}

.index-go-more {
  width: 120px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  border: 1px solid #404042;
  color: #404042;
  position: relative;
  margin-top: 40px;
}

.index-go-more-text {
  position: relative;
  z-index: 1;
}

.index-go-more:after {
  position: absolute;
  content: '';
  height: 30px;
  width: 0;
  left: 0;
  top: 0;
  background: #404042;
  transition: width 0.3s;
}

.index-go-more:hover {
  color: #ffffff;
}

.index-go-more:hover:after {
  width: 120px;
}

.index-grass-text {
  width: 460px;
  height: auto;
  padding: 30px 0 30px 70px;
  position: absolute;
  left: 0;
  z-index: 1;
}

.index-grass-text-eng {
  font-size: 50px;
  line-height: 60px;
  color: #ffffff;
}

.index-grass-text-title {
  width: 100%;
  margin: 30px 0;
  font-size: 30px;
  line-height: 35px;
  color: #000000;
}

.index-grass-text-tips {
  width: 100%;
  height: 34px;
  font-size: 20px;
  line-height: 34px;
  color: #000000;
}

.index-grass-text-content {
  width: 90%;
  font-size: 18px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.7);
}

.index-grass-go {
  width: 120px;
  height: 30px;
  color: #000000;
  text-align: center;
  line-height: 30px;
  border: 1px solid rgba(0, 0, 0, 0.7);
  position: relative;
  margin-top: 30px;
  cursor: pointer;
}

.index-grass-go-more-text {
  position: relative;
  z-index: 1;
}

.index-grass-go:after {
  position: absolute;
  content: '';
  height: 30px;
  width: 0;
  left: 0;
  top: 0;
  background: #ffffff;
  transition: width 0.3s;
}

.index-grass-go:hover {
  color: #404042;
}

.index-grass-go:hover:after {
  width: 120px;
}

.index-grass {
  width: 100%;
  cursor: pointer;
}

.index-line {
  width: 35%;
  height: 1px;
  background-color: #b8c4c0;
}

.index-tips {
  width: 120px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  color: #214b3d;
  border: 1px solid #b8c4c0;
  margin: 0 30px;
  cursor: pointer;
  position: relative;
}

.go-top10-info-text {
  position: relative;
  z-index: 1;
}

.index-tips:after {
  position: absolute;
  content: '';
  height: 30px;
  width: 0;
  left: 0;
  top: 0;
  background: #034c3b;
  transition: width 0.3s;
}

.index-tips:hover {
  color: #ffffff;
}

.index-tips:hover:after {
  width: 120px;
}

.index-three {
  /* width: 31%; */
  width: 29%;
  cursor: pointer;
  transition: all .1s ease-in-out,width .1s ease-in-out,top .1s linear;
}

.mouseActive {
  width: 35%;
}

.index-three-img {
  width: 100%;
}

.index-three-title {
  text-align: left;
  height: 54px;
  line-height: 27px;
  font-size: 16px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.index-three-tips {
  text-align: left;
  height: 25px;
  line-height: 25px;
  font-size: 14px;
  color: #888888;
}

@media (max-width: 767px) {
  .index-content {
    width: 90%;
    font-size: 12px;
  }

  .index-grass-text {
    width: 220px;
    height: auto;
    padding: 10px 0 10px 20px;
    position: absolute;
    left: 0;
    z-index: 1;
    font-weight: 200;
  }

  .index-story-text-eng {
    font-size: 18px;
    line-height: 30px;
    font-weight: 600px;
  }

  .index-story-text-title {
    font-size: 18px;
    margin: 20px 0;
    line-height: 20px;
    font-weight: 200;
  }

  .index-story-text-content {
    font-size: 12px;
    line-height: 18px;
    font-weight: 200;
  }

  .index-go-more {
    width: 70px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    border: 1px solid #404042;
    color: #404042;
    position: relative;
    margin-top: 20px;
    font-size: 12px;
    font-weight: 200;
  }

  .index-grass-text-eng {
    font-size: 18px;
    line-height: 30px;
    font-weight: 600px;
    color: #ffffff;
  }

  .index-grass-text-title {
    width: 100%;
    margin: 15px 0;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    font-weight: 200;
  }

  .index-grass-text-tips {
    width: 100%;
    height: 20px;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    font-weight: 200;
  }

  .index-grass-text-content {
    width: 90%;
    font-size: 12px;
    line-height: 15px;
    font-weight: 200;
    color: rgba(255, 255, 255, 0.7);
  }
}
</style>

<style>
.el-carousel__button {
  width: 15px !important;
  height: 15px !important;
  border-radius: 50%;
}

.el-carousel__indicator--horizontal {
  padding: 12px !important;
}

/* .el-carousel__container {
  height: 900px !important;
} */

/* @media (max-width: 767px) {
  .el-carousel__container {
    height: 300px;
  }
} */
</style>
