<template>
  <div class="index" id="indexTop">
    <div class="top-swiper">
      <el-carousel :height="swiperHeight + 'px'" :autoplay="true" :interval="2000" arrow="never">
        <el-carousel-item v-for="(item, index) in Banner" :key="index">
          <img :src="item.img" alt="" class="banner-img" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="index-content flex flex-direction align-center">
      <img src="../../assets/grass/grass_info_01.jpg" class="grass-content-img" alt="">
      <div class="grass-title flex flex-direction align-center">
        <!-- <img src="../../assets/grass/bencao_tel_con.png" class="grass-title-bg" alt=""> -->
        <div class="grass-title-block">专属特色配方</div>
        <!-- <div class="grass-tips-block">百倍舒专利成分</div> -->
      </div>
      <img src="../../assets/grass/grass_info_02.jpg" class="grass-content-imgs" alt="">
      <div class="grass-title flex flex-direction align-center">
        <div class="grass-content-block">春之唤采用本草嫩肤，延传美容养颜古方，</div>
        <div class="grass-content-block">特色成分百倍舒、桃颜舒、谷箱舒、致靓舒等，亲肤温和滋养，</div>
        <div class="grass-content-block" style="margin-bottom:10px;">很好的呵护娇嫩肌肤，带动护肤品营养吸收。</div>
        <!-- <div class="grass-tips-block">桃颜舒专利成分</div> -->
      </div>
      <!-- <img src="../../assets/grass/grass_info_03.jpg" class="grass-content-imgs" alt="">
      <div class="grass-title flex flex-direction align-center">
        <div class="grass-content-block">科技耀白，桃颜舒组分来自中药补血名方：桃红四物汤，</div>
        <div class="grass-content-block">源自《玉肌微义》和《医宗金鉴》，有养血活血功效。</div>
      </div> -->
    </div>
    <img src="../../assets/grass/bencao_04.jpg" class="index-content-banner-img" style="margin-top: 20px;" alt="">
    <div class="index-contents flex flex-direction align-center">
      <div v-for="(item, index) in storyList" :key="index">
        <div class="index-two flex justify-center align-center" :class="(index + 1) % 2 == 0 ? '' : 'bg-green'">
          <img :src="item.url1" class="index-two-img" alt="">
          <div class="index-two-text flex flex-direction justify-center">
            <div class="index-two-text-title" v-html="item.title"></div>
            <div class="index-two-text-tips" v-html="item.content"></div>
          </div>
        </div>
      </div>
      <img src="../../assets/grass/bencao_11.jpg" class="index-content-banner-img" style="margin-top: 70px;" alt="">
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { getBannerList } from '@/api';

export default {
  name: "Grass",

  data() {
    return {
      Banner: [],
      title: "",
      swiperHeight: 900,
      storyList: [
        // {
        //   id: 1,
        //   url1: require("../../assets/grass/bencao_05.jpg"),
        //   title: "实验室研发介绍",
        //   content: "<div>与广州中医药大学、上海交大药学院合作，将微生物发酵工程与化妆品应用相结合的研发基地。</div><div style='margin-top:20px;'>与上海交通大学药学院、药学类“985工程”重点院校签署合作并承担众多海外重大重点科研项目，获得超过200项国家发明专利认证。</div>",
        // },
        {
          id: 1,
          url1: require("../../assets/grass/bencao_05.jpg"),
          title: "分子晶体破壁提取技术",
          content: "恒低温连续提取，多级膜分离和纯化，定量标准拼配，真空低温重组结晶等生产方式，在保证植物天然全成分提取质量的前提下，有效去除农药及重金属残留，使药效成分充分释放，分子晶体提取物更有利于皮肤吸收！",
        },
        {
          id: 2,
          url1: require("../../assets/grass/bencao_06.jpg"),
          title: "本草发酵科技",
          content: "春之唤核心技术本草发酵科技是一项创新、绿色、安全、环保的高科技萃取技术，拥有多项发明专利。以源自大自然的天然本草植物以及精心配伍的组方结合生物发酵技术，打造出易于肌肤吸收的本草护肤品。",
        },
        {
          id: 3,
          url1: require("../../assets/grass/bencao_07.jpg"),
          title: "UFD透皮渗透专利技术",
          content: "打开肌肤营养通道，让肌肤像海绵般吸收营养成分，形成我们的微美滴、小液滴成分，有效渗透，层层深入肌肤，传递丰沛营养。",
        },
        {
          id: 4,
          url1: require("../../assets/grass/bencao_08.jpg"),
          title: "微美滴传新技术",
          content: "助力肌肤吸收，突破性的渗透肌肤技术，源源不断为肌肤输送养分。",
        },
        {
          id: 5,
          url1: require("../../assets/grass/bencao_09.jpg"),
          title: "一种低温微射流法提取人参活性肽和人参皂苷浓缩液的方法",
          content: "无水配方原料，春之唤自有发酵液，采用酵母杆菌加上人参根提取的发酵液作为主原料，辅料为黑灵芝提取物。低温微射流法提取人参活性肽和人参皂苷浓缩液的方法，渗透技术是正常护肤品的N多倍。",
        },
        {
          id: 6,
          url1: require("../../assets/grass/bencao_10.jpg"),
          title: "<div>人参水蛭素</div><div>发酵液的配置方法</div>",
          content: "发酵后获取10倍活性功效的人参酵素。更易被肌肤深层吸收，加速肌肤代谢，让肌肤恢复亮、透、白。",
        },
      ],
    };
  },

  mounted(){
    this.winWidth();
    window.addEventListener("resize", this.winWidth,false)
  },

  created() {
    this.$emit("isAbout","about")
    this.getTopBanner()
  },

  metaInfo(){
    return {
      title: "探索本草嫩肤-春之唤官方网站",
    }
  },

  methods: {
    // 屏幕宽度
    winWidth(){
      var w = document.documentElement.clientWidth || document.body.clientWidth;
      this.swiperHeight = Math.round((900*w)/1920);
      // console.log(this.swiperHeight)
      // var h = document.documentElement.clientHeight || document.body.clientHeight;
      // this.swiperHeight = Math.round(h * 0.9);
      // // console.log(this.swiperHeight)
      // console.log(h)
      // console.log(this.Banner[0].img)
    },
    
    // 顶部banner
    async getTopBanner(){
      // console.log("ddd ")
      let data = await getBannerList({
        pos_id: "5"
      });
      this.Banner = data.banner_list;
    },
  },
};
</script>

<style scoped>
.index {
  width: 100%;
  height: 100%;
}

.top-swiper {
  width: 100%;
  /* height: 900px; */
  position: relative;
  z-index: 1;
}

.banner-img {
  width: 100%;
  height: 100%;
}

.index-content {
  width: 100%;
  padding-bottom: 40px;
}

.grass-content-img {
  width: 100%;
  display: block;
}

.grass-content-imgs {
  width: 60%;
  display: block;
}

.grass-title {
  width: 60%;
  padding: 15px 0;
  position: relative;
  margin-top: 20px;
}

.grass-title-bg {
  width: 150px;
  position: absolute;
  top: 0;
  left: 0;
}

.grass-title-block {
  width: 100%;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 28px;
  letter-spacing: 2px;
  color: #024c3b;
}

.grass-tips-block {
  padding: 4px 8px;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 15px;
  color: #ffffff;
  background-color: #024c3b;
}

.grass-content-block{
  font-size: 16px;
  line-height: 24px;
}

.index-contents {
  width: 1100px;
  margin: 20px 0;
  padding-bottom: 50px;
}

.index-content-banner-img {
  width: 100%;
}

.index-top-title {
  margin-top: 30px;
  font-size: 38px;
  color: #3d847e;
  letter-spacing: 4px;
}

.index-top-tips {
  width: 90%;
  text-align: left;
  font-size: 18px;
  margin-top: 10px;
}

.index-top-img {
  width: 60%;
  margin-top: 15px;
}

.index-top-bottom {
  width: 90%;
  text-align: center;
  font-size: 18px;
}

.index-two {
  width: 100%;
  /* height: 360px; */
  margin-top: 60px;
}

.index-two-img {
  /* width: 50%;
  height: 430px; */
  width: 520px;
  /* height: 360px; */
  display: block;
}

.index-two-text {
  width: 520px;
  /* height: 300px; */
  padding: 30px;
}

.bg-green {
  background-color: #ecf2f2;
}

.index-two-text-title {
  font-size: 32px;
  line-height: 50px;
}

.index-two-text-tips {
  margin-top: 30px;
  font-size: 16px;
  line-height: 35px;
}

.index-bottom-text {
  width: 100%;
}

.index-bottom-text-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 15px;
}

.index-bottom-text-title-content {
  font-size: 16px;
  line-height: 25px;
}

.index-content-banner-bottom-img {
  width: 100%;
  display: block;
  margin: 40px 0;
}

.video-play{
  width: 100%;
  height: 240px;
}
</style>

<style>
.el-carousel__button {
  width: 15px !important;
  height: 15px !important;
  border-radius: 50%;
}

.el-carousel__indicator--horizontal {
  padding: 12px !important;
}

/* .el-carousel__container {
  height: 900px !important;
} */
</style>
