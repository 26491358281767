<template>
  <div class="index flex flex-direction align-center" id="indexTop">
    <div class="top-swiper">
      <el-carousel :height="swiperHeight + 'px'" :autoplay="true" :interval="2000" arrow="never">
        <el-carousel-item v-for="(item, index) in Banner" :key="index">
          <img :src="item.url" alt="" class="banner-img" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- <img src="../../assets/produce/product_banner.jpg" class="banner-img" /> -->
    <div class="produce-classify flex justify-around align-start">
      <div class="classify-wrap" @click="chooseClassify('0')">全部</div>
      <div class="classify-wrap" v-for="(item, index) in classifyList" :key="index" @click="chooseClassify(item.cat_id)">{{ item.cat_name }}</div>
    </div>
    <div class="produce-menu">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-submenu index="1">
          <template slot="title">{{ title1 }}</template>
          <el-menu-item :index="'1-' + (indexs+1) + ''" v-for="(items, indexs) in cate_list1" :key="indexs">{{ items.cat_name }}</el-menu-item>
        </el-submenu>
        <el-submenu index="2">
          <template slot="title">{{ title2 }}</template>
          <el-menu-item :index="'2-' + (indexs+1) + ''" v-for="(items, indexs) in cate_list2" :key="indexs">{{ items.cat_name }}</el-menu-item>
        </el-submenu>
        <el-submenu index="3">
          <template slot="title">{{ title3 }}</template>
          <el-menu-item :index="'3-' + (indexs+1) + ''" v-for="(items, indexs) in cate_list3" :key="indexs">{{ items.cat_name }}</el-menu-item>
        </el-submenu>
        <!-- <el-submenu index="2">
          <template slot="title">我的工作台2</template>
          <el-menu-item index="2-1">选项1</el-menu-item>
          <el-menu-item index="2-2">选项2</el-menu-item>
          <el-menu-item index="2-3">选项3</el-menu-item>
        </el-submenu> -->
      </el-menu>
    </div>
    
    <div class="produce-wrap flex flex-wrap align-start" v-if="produceList">
      <div class="produce-block flex flex-direction align-center" :class="(index + 1) % 3 == 0 ? 'no-margin-right' : ''" v-for="(item, index) in produceList" :key="index"  @click="goProduceInfo(item.goods_id)">
        <img :src="item.image_md5" class="produce-img" alt="">
        <div class="produce-title">{{ item.goods_title }}</div>
        <div class="produce-title-tips">{{ item.material}}</div>
        <div class="produce-go"><span class="go-top10-info-text">查看详情</span></div>
      </div>
    </div>
    <div class="produce-wrap" style="height:100px;line-height:100px;text-align:center;" v-else>
      没有查询到商品
    </div>
    <div class="page-block">
      <el-pagination layout="prev, pager, next" :page-count="pageCount" @current-change="pageCurrentChange" :current-page="page" @prev-click="prevPage" @next-click="nextClick"></el-pagination>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { getGoodsList } from '@/api';

export default {
  name: "Produce",

  data() {
    return {
      keywords: "",
      description: "",
      activeIndex: "1",
      Banner: [
        { url: require("../../assets/produce/product_banner.jpg") },
      ],
      classifyList: [],
      cate_list1: [],
      cate_list2: [],
      cate_list3: [],
      title1: "按类别分类",
      title2: "按功效分类",
      title3: "按系列分类",
      produceList: [],
      pageCount: 0,
      page: 1,
      nav_id: '0',
      title: "",
      swiperHeight: 950,
    };
  },

  mounted(){
    this.winWidth();
    window.addEventListener("resize", this.winWidth,false)
  },

  created() {
    this.$emit("isProduce","produce")
    // this.page = 1;
    this.getMoreList();
  },

  metaInfo:{
      title: "春之唤产品-春之唤官方网站",
  },

  methods: {
    // 屏幕宽度
    winWidth(){
      var w = document.documentElement.clientWidth || document.body.clientWidth;
      this.swiperHeight = Math.round((950*w)/1920);
      // console.log(this.swiperHeight)
      // var h = document.documentElement.clientHeight || document.body.clientHeight;
      // this.swiperHeight = Math.round(h * 0.9);
      // // console.log(this.swiperHeight)
      // console.log(h)
      // console.log(this.Banner[0].img)
    },

    // 选择顶部分类
    chooseClassify(cate_id){
      this.nav_id = cate_id
      this.cate_id = ''
      this.tags = ''
      this.trend_cat_id = ''
      this.page = 1;
      this.getMoreList();
    },

    // 分类打开
    handleSelect(key, keyPath) {
      var index_array = key.split("-");
      var index = index_array[0];
      var child_index = index_array[1] - 1;
      if(index === '1') {
        this.title1 = this.cate_list1[child_index].cat_name
        this.cate_id = this.cate_list1[child_index].cat_id
        this.nav_id = ''
        this.tags = ''
        this.trend_cat_id = ''
        this.title2 = "按功效分类"
        this.title3 = "按系列分类"
      } else if(index === '2') {
        this.title2 = this.cate_list2[child_index].cat_name
        this.tags = this.cate_list2[child_index].cat_name
        this.nav_id = ''
        this.cate_id = ''
        this.trend_cat_id = '',
        this.title1 = "按类别分类"
        this.title3 = "按系列分类"
      } else if (index === '3') {
        this.title3 = this.cate_list3[child_index].cat_name
        this.trend_cat_id = this.cate_list3[child_index].cat_id
        this.nav_id = ''
        this.cate_id = ''
        this.tags = '',
        this.title1 = "按类别分类"
        this.title2 = "按功效分类"
      }
      this.page = 1;
      this.getMoreList();
    },

    // 产品列表
    async getMoreList(){
      let data = await getGoodsList({
        nav_id: this.nav_id ? this.nav_id: '',
        cat_id: this.cate_id ? this.cate_id : '',
        tags: this.tags ? this.tags : '',
        trend_cat_id: this.trend_cat_id ? this.trend_cat_id : '',
        page: this.page,
        // limit: "10",
      })
      this.pageCount = data.total_page;
      this.classifyList = data.cat_list4;
      this.cate_list1 = data.cat_list;
      this.cate_list2 = data.cat_list2;
      this.cate_list3 = data.cat_list3;

      if(data.list != '') {
        this.produceList = data.list;
      } {
        return false
      }
    },

    // 产品详情
    goProduceInfo(id){
      router.replace({
        path: "/companyProduceInfo",
        query: { id: id },
      })
    },

    // 页码发生改变时
    pageCurrentChange(val){
      // console.log(val)
      this.page = val;
      this.getMoreList();
    },

    // 上一页
    prevPage(val){
      // console.log(val)
    },

    // 下一页
    nextClick(val){
      // console.log(val)
    },
  },
};
</script>

<style scoped>
.index {
  width: 100%;
  height: 100%;
}

.top-swiper {
  width: 100%;
  /* height: 200px; */
  position: relative;
  z-index: 1;
}

.banner-img {
  width: 100%;
  height: 100%;
  display: block;
}

/* .banner-img {
  width: 100%;
  position: relative;
  z-index: 1;
  display: block;
} */

.produce-classify {
  /* width: 50%; */
  width: 960px;
  height: 50px;
  background-color: #034b3d;
  color: #ffffff;
  font-size: 16px;
  line-height: 50px;
  margin-top: -25px;
  position: relative;
  z-index: 2;
}

.classify-wrap {
  width: 16%;
  text-align: center;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
}

.produce-menu {
  /* width: 50%; */
  width: 960px;
}

.produce-wrap {
  /* width: 50%; */
  width: 960px;
  margin-top: 20px;
}

.produce-block {
  /* width: 33.3%; */
  width: 250px;
  /* width: 300px; */
  margin-bottom: 40px;
  margin-right: 105px;
}

.produce-block.no-margin-right {
  margin-right: 0;
}

.produce-img {
  width: 250px;
  height: 250px;
  cursor: pointer;
  margin-bottom: 10px;
}

.produce-title {
  width: 250px;
  height: 45px;
  line-height: 22px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.produce-title-tips {
  width: 250px;
  text-align: center;
  color: #a7a7a7;
  height: 50px;
  line-height: 25px;
  font-size: 12px;
  cursor: pointer;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.produce-go {
  width: 70px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 10px;
  color: #818181;
  border: 1px solid #818181;
  margin-top: 12px;
  cursor: pointer;
  position: relative;
}

.produce-go:after{
  position: absolute;
  content: '';
  height: 20px;
  width: 0;
  left: 0;
  top: 0;
  background: #034c3b;
  transition: width 0.3s;
}

.go-top10-info-text {
  position: relative;
  z-index: 1;
}

.produce-go:hover {
  color: #ffffff;
}

.produce-go:hover:after {
  width: 70px;
}

.page-block {
  width: 65%;
  height: 40px;
  margin-top: 10px;
  background: none;
}
</style>

<style>
.page-block .el-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}

.page-block .el-pagination button {
  background: none;
}

.page-block .el-pager li {
  background: none;
}

.page-block .el-dialog, .el-pager li {
  background: none;
}

.page-block .el-pagination button:disabled {
  background: none;
}

.produce-menu .el-menu.el-menu--horizontal {
  border-bottom: none !important;
}

.produce-menu .el-submenu__title {
  width: 150px;
  /* width: 30%; */
  height: 45px !important;
  line-height: 45px !important;
  border-bottom: 1px solid #034b3d !important;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .el-submenu__title {
  font-size: 14px;
  color: #303133 !important;
}

.el-menu-item {
  font-size: 14px;
  color: #303133 !important;
} */

.produce-menu .el-menu--horizontal>.el-submenu {
  width: 150px !important;
}

.produce-menu .el-menu--horizontal>.el-submenu .el-submenu__title {
  font-size: 14px;
  color: #303133 !important;
}

.produce-menu .el-menu--collapse .el-menu .el-submenu, .el-menu--popup {
  min-width: 150px !important;
}

/* .produce-menu .el-menu--popup {
  max-width: 150px !important;
} */

.produce-menu .el-menu--horizontal>.el-submenu {

  display: flex;
  justify-content: center;
  align-items: center;
  float: none !important;
}

.produce-menu .el-menu-demo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: none !important;
}

.produce-menu .el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {
  margin-top: 0 !important;
  margin-left: 20px !important;
}

.produce-menu .el-submenu__icon-arrow {
  margin-top: 0 !important;
  margin-left: 20px !important;
}

.el-menu::after,.el-menu::before {
  content: none !important;
}
</style>
